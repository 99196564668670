import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { YMaps, Map, Placemark } from 'react-yandex-maps';


const MapPage = () => {
  const [mapWidth, setMapWidth] = useState(0);
  const [mapHeight, setMapHeight] = useState(0);
  const heightMinus = 140;
    useEffect(() => {
      if (typeof window!=="undefined") {
        setMapHeight(window.innerHeight - heightMinus);
        setMapWidth(window.innerWidth < 600 ? window.innerWidth - 32 : window.innerWidth - 48);
      }
    }, [])
    const resizeHandler = () => {
      if (typeof window!=="undefined") {
        setMapWidth(window.innerWidth < 600 ? window.innerWidth - 32 : window.innerWidth - 48);
        setMapHeight(window.innerHeight - heightMinus);
      }
    };
    useEffect(() => {
      if (typeof window!=="undefined") {
        window.addEventListener('resize', resizeHandler)
      }
      return () => {
        if (typeof window!=="undefined") {
          window.removeEventListener('resize', resizeHandler);
        }
      }
    });


  return (
    <Layout>
      <SEO />
        <YMaps>
            <Map defaultState={{ center: [52.201048, 24.018678], zoom: 17, scroll: false}} width={mapWidth} height={mapHeight}  >
            <Placemark geometry={[52.201048, 24.018678]}/>
            </Map>
        </YMaps>
    </Layout>
  )
}
export default MapPage
